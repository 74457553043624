import { NBXStorage } from '../storage';
// eslint-disable-next-line import/no-cycle
import { post } from '../fetch-api';
import { getAuthBaseUrl } from './url';
import { Claims, Session } from '../types';
// eslint-disable-next-line import/no-cycle
import { getSession } from './session';

export interface UserTokenResponse {
  claims: Claims;
  exp: number;
  token: string;
}

export const clearGlobalFetchUserTokenPromise = (): void => {
  window.fetchUserTokenPromise = null;
};

export async function refreshUserToken(session: Session): Promise<string> {
  if (window.fetchUserTokenPromise) {
    console.log(`[helpers.refreshUserToken] return fetchUserTokenPromise (session.exp=${session?.exp})`);
    setTimeout(clearGlobalFetchUserTokenPromise, 15000);
    return window.fetchUserTokenPromise;
  }
  window.fetchUserTokenPromise = doRefreshUserToken(session);
  return window.fetchUserTokenPromise;
}

let userTokenRefreshTimeout: ReturnType<typeof setTimeout>;

export const setUserTokensDebounceRefresh = (response: UserTokenResponse): void => {
  if (userTokenRefreshTimeout) clearTimeout(userTokenRefreshTimeout);
  userTokenRefreshTimeout = setTimeout(
    () => getSession().then(refreshUserToken),
    (response.claims.exp - response.claims.iat) * 1000 * 0.65
  );
};

export async function doRefreshUserToken(session: Session): Promise<string> {
  try {
    if (!session) {
      console.log(`[helpers.doRefreshUserToken] cannot refresh user token: no session`);
      throw new Error('doRefreshUserToken: no session');
    }
    const response = (await post(
      `${getAuthBaseUrl()}/users/${session.userId}/tokens`,
      { json: {} },
      session
    )) as UserTokenResponse;
    await NBXStorage.setItem(session.userId, response.token);
    setUserTokensDebounceRefresh(response);
    setTimeout(clearGlobalFetchUserTokenPromise, 15000);
    return response.token;
  } catch (e) {
    console.warn(
      `doRefreshUserToken: will logout - failed to refresh user token using session.exp=${session?.exp}`
    );
    clearGlobalFetchUserTokenPromise();
    if (window.PLATFORM === 'web') {
      window.LOGOUT();
    } else {
      window.SUSPEND_MOBILE_SESSION();
    }
    throw e;
  }
}
