import {
  authTypes,
  uiTypes,
  LANG,
  NotificationInternalParams,
  NotificationExternalParams
} from '../subscribe/eventTypes';
import { NBXStorage } from '../storage';
import { AccountClaims, Claims, Session, UserAccount, User } from '../types';

// TODO: is this all the publish events?
// no, setItems is not a notification

export function publishEvent(type: string, detail: unknown): void {
  let detailObj: Record<'detail', unknown> | undefined;
  if (detail) detailObj = { detail };
  const event = new CustomEvent(type, detailObj);
  window.dispatchEvent(event);
}

export function publishAuthUserChanged(user: User): void {
  user ? NBXStorage.setItem('user', JSON.stringify(user)) : NBXStorage.removeItem('user');
  publishEvent(authTypes.AUTH_USER_CHANGED, user);
}

export function publishAuthUserClaimsChanged(claims: Claims): void {
  NBXStorage.setItem('claims', JSON.stringify(claims));
  publishEvent(authTypes.AUTH_USER_CLAIMS_CHANGED, claims);
}

export function publishAuthAccountClaimsChanged(claims: AccountClaims): void {
  NBXStorage.setItem('account-claims', JSON.stringify(claims));
  publishEvent(authTypes.AUTH_ACCOUNT_CLAIMS_CHANGED, claims);
}

export function publishAuthSessionChanged(session: Session): void {
  session ? NBXStorage.setItem('session', JSON.stringify(session)) : NBXStorage.removeItem('session');

  publishEvent(authTypes.AUTH_SESSION_CHANGED, session);
}

export function publishAuthStep(step: string): void {
  publishEvent(authTypes.AUTH_STEP, step);
}

export function publishAuthAccountIdChanged(accountId: string): void {
  accountId ? NBXStorage.setItem('active-account', accountId) : NBXStorage.removeItem('active-account');

  publishEvent(authTypes.AUTH_ACCOUNT_ID_CHANGED, accountId);
}

export function publishAuthUserAccountsChanged(userAccounts: Array<UserAccount>): void {
  NBXStorage.setItem('user-accounts', JSON.stringify(userAccounts));
  publishEvent(authTypes.AUTH_USER_ACCOUNTS_CHANGED, userAccounts);
}

export function publishTheme(theme: string, storeTheme = true): void {
  if (storeTheme) NBXStorage.setItem('theme', theme, { useLocalStorage: true });
  publishEvent(uiTypes.UI_THEME_CHANGED, theme);
}

function publishNotification({
  type,
  title,
  message,
  timeout = 5000,
  onTimeout = undefined
}: NotificationInternalParams): void {
  publishEvent(uiTypes.UI_SET_NOTIFICATION, { type, title, message, timeout, onTimeout });
}

export function publishAlertNotification(alertType, timeout, onTimeout): void {
  publishNotification({ type: 'alert', message: alertType, timeout, onTimeout });
}

export function publishSuccessNotification({ title, message }: NotificationExternalParams): void {
  publishNotification({ type: 'success', title, message });
}

export function publishErrorNotification(
  { title, message }: NotificationExternalParams,
  rawError?: string
): void {
  publishNotification({ type: 'error', title, message });
  if (rawError) console.error(rawError); //eslint-disable-line
}

export function publishNotificationRemoved(key): void {
  publishEvent(uiTypes.UI_UNSET_NOTIFICATION, key);
}

export function publishFocusQuickbuy(): void {
  publishEvent(uiTypes.UI_FOCUS_QUICKBUY, null);
}

export function publishLang(lang): void {
  window.PUBLISH(LANG, lang);
  NBXStorage.setItem('i18nextLng', lang, { useLocalStorage: true });
  document?.documentElement?.setAttribute(LANG, lang);
}
